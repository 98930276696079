<template>

  <v-card class="mt-2 ml-2 mr-2">
    <FKomoditiTable ref="refTabKomoditiClick"/>
  </v-card>

</template>

<script>
import FKomoditiTable from "../../components/admin_komoditi/komoditi/FKomoditiTable";

export default {
  components: { FKomoditiTable },
  data() {
    return {
      firstTabTitle: 'KOMODITI',
      firstTabIcon: ''
    }
  },
  methods: {
    tabKomoditiClick(){
      try {
        this.$refs.refTabKomoditiClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },

  }
}
</script>

<style scoped>

</style>