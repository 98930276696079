export default class FKomoditi {
  constructor(
    id,
    kode1="",
    description ="",
    fdivisionBean =0,
    statusActive =true,
    baseId,
    lang,

    fkomoditiTypeBean =0,

    fareaBean=0,
    fsubAreaBean =0,
    tahun="",
    lat=0.0,
    lon=0.0,

    nilai1 = 0.0,
    nilai2 = 0.0,

    city ="",

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.statusActive = statusActive;
    this.baseId = baseId;
    this.lang = lang;

    this.fkomoditiTypeBean = fkomoditiTypeBean;
    this.fareaBean = fareaBean;
    this.fsubAreaBean = fsubAreaBean;
    this.tahun = tahun;
    this.lat = lat;
    this.lon = lon;

    this.nilai1 = nilai1;
    this.nilai2 = nilai2;

    this.city = city;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
