import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FKomoditiService {
    getApiUrl() {
        return API_URL
    }

    getAllFKomoditiByTypePublic(typeBean){
        return axios.get(API_URL + `public/getAllFKomoditiByTypePublic/${typeBean}`, { headers: authHeader() });
    }
    getAllFKomoditiByTypesPublic(fkomoditiTypeIds){
        return axios.get(API_URL + `public/getAllFKomoditiByTypesPublic/${fkomoditiTypeIds}`, { headers: authHeader() });
    }

    getAllFKomoditiByTypePublicTerbesarJumlah(typeBean, terbesarJumlah){
        return axios.get(API_URL + `public/getAllFKomoditiByTypePublicTerbesarJumlah/${typeBean}/${terbesarJumlah}`, { headers: authHeader() });
    }
    getAllFKomoditiByTypesAndFareaBeanPublic(fkomoditiTypeIds, fareaBean){
        return axios.get(API_URL + `public/getAllFKomoditiByTypesAndFareaBeanPublic/${fkomoditiTypeIds}/${fareaBean}`, { headers: authHeader() });
    }

    getAllFKomoditiByCityPublic(city){
        return axios.get(API_URL + `public/getAllFKomoditiByCityPublic/${city}`, { headers: authHeader() });
    }
    getAllFKomoditiByCitySimplePublic(city){
        return axios.get(API_URL + `public/getAllFKomoditiByCitySimplePublic/${city}`, { headers: authHeader() });
    }

    getAllFKomoditiByFareaPublic(fAreaBean){
        return axios.get(API_URL + `public/getAllFKomoditiByFareaPublic/${fAreaBean}`, { headers: authHeader() });
    }

    getAllFKomoditi(){
        return axios.get(API_URL + `getAllFKomoditi`, { headers: authHeader() });
    }
    getAllFKomoditiByOrgLevel(){
        return axios.get(API_URL + `getAllFKomoditiByOrgLevel`, { headers: authHeader() });
    }

    getAllFKomoditiContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFKomoditi`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFKomoditiContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getPostAllFKomoditiContainingExt(item){
        return axios.post(API_URL + `getPostAllFKomoditiContainingExt`, item, { headers: authHeader() });
    }

    getFKomoditiById(id){
        return axios.get(API_URL + `getFKomoditiById/${id}`, { headers: authHeader() });
    }
    updateFKomoditi(item){
        return axios.put(API_URL + `updateFKomoditi/${item.id}`, item, {headers: authHeader()})
    }
    createFKomoditi(item){
        return axios.post(API_URL + `createFKomoditi`, item, {headers: authHeader()})
    }
    createFKomoditiMultiple(item){
        return axios.post(API_URL + `createFKomoditiMultiple`, item, {headers: authHeader()})
    }
    deleteFKomoditi(id){
        return axios.delete(API_URL + `deleteFKomoditi/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFKomoditi(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFKomoditi`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFKomoditi`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FKomoditiService()