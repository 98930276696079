export default class FKomoditiTypeType {
  constructor(
    id,
    kode1="",
    description="",
    fdivisionBean=0,
    fsectorTypeBean=0,
    fsatuanBean=0,
    statusActive=true,
    baseId,
    lang,


    avatarImage,
    markerImage1,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.fdivisionBean = fdivisionBean;
    this.statusActive = statusActive;
    this.baseId = baseId;
    this.lang = lang;

    this.fsectorTypeBean = fsectorTypeBean;
    this.fsatuanBean = fsatuanBean;

    this.avatarImage = avatarImage;
    this.markerImage1 = markerImage1;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
