<template>
  <div class="align-self-center">
    <v-dialog
      v-model="dialogShow"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeDialog"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar class="grey lighten-3" elevation="0" dense>
          <v-toolbar-title>Read File Excel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="justify-center">
          <v-row justify="center" align="center">
            <v-col cols="5">
              <v-file-input
                v-model="currentFile"
                show-size
                counter
                label="Pilih File Excel"
                accept=".xls,.xlsx"
                @change="selectFile"
              ></v-file-input>
            </v-col>
            <v-col cols="3" class="pl-1">
              <v-autocomplete
                v-model="fkomoditiTypeBeanSelected"
                :rules="rulesNotEmtpy"
                :items="itemsFKomoditiType"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Jenis Daya Dukung"
                hint="Jenis Daya Dukung"
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pl-1">
              <v-autocomplete
                v-model="fdivisionBeanSelected"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Pemilik Data (Kab/Kota)"
                hint="Kosong, maka akan disesuikan dengan kab/kota masing2"
                persistent-hint
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" class="pl-2">
              <v-btn
                color="success"
                small
                @click="startUpload"
                :disabled="
                  !currentFile ||
                  fkomoditiTypeBeanSelected === 0 ||
                  fkomoditiTypeBeanSelected === null ||
                  fdivisionBeanSelected === 0 ||
                  fdivisionBeanSelected === null ||
                  sudahUpload === true
                "
              >
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row v-if="currentFile">
            <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headers"
                :items="itemsFKomoditiComputed"
                item-key="name"
                class="elevation-1"
                height="400"
                calculate-widths
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.number`]="{ item }">
                  <span
                    ><v-icon small color="green" v-if="item.statusUpload === 1"
                      >mdi-check-circle</v-icon
                    ></span
                  >
                  <span
                    ><v-icon small color="red" v-if="item.statusUpload === 2"
                      >mdi-close</v-icon
                    ></span
                  >
                  {{ item.number }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <div v-if="progress">
            <div>
              <v-progress-linear
                v-model="progress"
                color="light-blue"
                height="25"
                reactive
              >
                <strong>{{ progress }} %</strong>
              </v-progress-linear>
            </div>
          </div>

          <v-row v-if="previewImage" justify="center">
            <v-img
              class="ma-2"
              :src="previewImage"
              alt=""
              max-width="300px"
              max-height="300px"
            ></v-img>
          </v-row>
        </v-card-text>
      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>
    </v-dialog>

    <v-dialog v-model="dialogProgres" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import FKomoditi from "../../../models/f-komoditi";
import FKomoditiService from "../../../services/apiservices/f-komoditi-service";

export default {
  name: "ImportExcel",
  props: {
    parentId: Number,
    itemsFDivision: [],
    itemsFKomoditiType: [],
    itemsFArea: [],
    itemsFSubArea: [],
  },
  components: {},
  data() {
    return {
      sudahUpload: false,

      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      currentFile: undefined,
      previewImage: undefined,

      fdivisionBeanSelected: 0,
      fkomoditiTypeBeanSelected: 0,
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      progress: 0,
      message: "",
      description: "",

      headers: [
        {
          text: "No",
          value: "number",
          width: "8%",
          sortable: false,
        },
        { text: "Nama", value: "description" },
        { text: "Kecamatan", value: "district" },
        { text: "Kab/Kota", value: "city" },
        { text: "Lat", value: "lat" },
        { text: "Lon", value: "lon" },
        { text: "tahun", value: "tahun" },
        { text: "nilai1", value: "nilai1" },
        { text: "nilai2", value: "nilai2" },
      ],
      itemsFKomoditi: [],
    };
  },
  computed: {
    itemsFKomoditiComputed() {
      return this.itemsFKomoditi;
    },
  },

  methods: {
    selectFile(file) {
      this.currentFile = file;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";

      let rowKe = 0;
      readXlsxFile(this.currentFile).then((rows) => {
        rows.forEach((item) => {
          if (rowKe > 0) {
            // console.log(JSON.stringify(item[1]))
            // console.log(JSON.stringify(item[1]))

            let fFKomoditiBean = new FKomoditi();
            fFKomoditiBean.number = rowKe + 1;

            // if (item[1].toUpperCase() ==="FISIK" || item[1].toUpperCase() ==="FSK" ) {
            //   fFKomoditiBean.jenisPekerjaan = "FSK"
            // }else {
            //   fFKomoditiBean.jenisPekerjaan = "NONFSK"
            // }

            fFKomoditiBean.statusUpload = 0;
            fFKomoditiBean.description = item[0] !== null ? item[0].trim() : "";
            fFKomoditiBean.district = item[1] !== null ? item[1].trim() : "";
            fFKomoditiBean.city = item[2] !== null ? item[2].trim() : "";
            fFKomoditiBean.lat = item[3] !== null ? Number(item[3]) : 0.0;
            fFKomoditiBean.lon = item[4] !== null ? Number(item[4]) : 0.0;

            fFKomoditiBean.tahun = item[5] !== null ? Number(item[5]) : 0;

            fFKomoditiBean.nilai1 = item[6] !== null ? Number(item[6]) : 0.0;
            fFKomoditiBean.nilai2 = item[7] !== null ? Number(item[7]) : 0.0;

            // if (isNaN(Date.parse(fFKomoditiBean.tglAdendum))){
            //   fFKomoditiBean.tglAdendum = '2020-01-01'
            // }

            this.itemsFKomoditi.push(fFKomoditiBean);
          }

          rowKe++;
        });
      });
    },

    startUpload() {
      if (!this.currentFile) {
        this.message = "Please select an Image!";
        return;
      }
      if (
        this.fkomoditiTypeBeanSelected === undefined ||
        this.fkomoditiTypeBeanSelected === 0
      ) {
        this.message = "Tipe Daya Dukung Belum Dipilih!";
        return;
      }

      let items = [];
      this.itemsFKomoditi.forEach((item) => {
        let newItem = item;
        newItem.id = 0;
        newItem.fkomoditiTypeBean = this.fkomoditiTypeBeanSelected;

        if (this.fdivisionBeanSelected !== 0) {
          newItem.fdivisionBean = this.fdivisionBeanSelected;
        } else {
          const div = this.itemsFDivision.filter(
            (x) =>
              x.description.trim().toUpperCase() ===
              newItem.city.trim().toUpperCase()
          );
          try {
            newItem.fdivisionBean = div[0].id;
          } catch (e) {
            e.toString();
          }
        }

        const kabKota = this.itemsFArea.filter(
          (x) =>
            x.description.trim().toUpperCase() ===
            newItem.city.trim().toUpperCase()
        );
        try {
          newItem.fareaBean = kabKota[0].id;
        } catch (e) {
          e.toString();
        }

        const kecamatan = this.itemsFSubArea.filter(
          (x) =>
            x.description.trim().toUpperCase() ===
              newItem.district.trim().toUpperCase() ||
            x.description.replace(/\s+/g, "").toUpperCase() ===
              newItem.district.replace(/\s+/g, "").toUpperCase()
        );
        // console.log(JSON.stringify(kecamatan))
        // console.log(newItem.district)
        try {
          newItem.fsubAreaBean = kecamatan[0].id;
        } catch (e) {
          e.toString();
        }

        if (
          newItem.fdivisionBean > 0 &&
          newItem.fsubAreaBean > 0 &&
          (newItem.nilai1 > 0 || newItem.nilai2 > 0)
        ) {
          items.push(newItem);
        }
      });

      // console.log(JSON.stringify(items))

      FKomoditiService.createFKomoditiMultiple(items).then(
        (response) => {
          // console.log(response.data.length)

          this.itemsFKomoditi = [];

          response.data.forEach((itemResponse) => {
            const newItem = Object.assign({}, itemResponse);
            try {
              this.$nextTick(() => {
                newItem.statusUpload = 1;
                this.itemsFKomoditi.push(newItem);
              });
            } catch (e) {
              newItem.statusUpload = 2;
              this.itemsFKomoditi.push(newItem);
              console.log(e);
            }
          });
          this.sudahUpload = true;
        },
        (error) => {
          console.log(error.data);
        }
      );
    },

    showDialog() {
      this.dialogShow = true;
      this.currentFile = undefined;
      this.previewImage = undefined;
      this.progress = 0;
      this.message = "";

      this.sudahUpload = false;

      this.itemsFKomoditi = [];
    },
    closeDialog() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      }
      if (this.sudahUpload) {
        this.$emit("importExcelCompleted", "OKE");
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>
